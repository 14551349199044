export const AD_BANNERS_TYPE = "banner";
export const AD_POPUP_TYPE = "popup";
export const AD_PRODUCTS_RECTANGLE_TYPE = "product_list_rectangle";
export const AD_PRODUCTS_SQUARE_TYPE = "product_list_square";
export const AD_PRODUCT_TYPE = "product_detail";
export const AD_PROMOTION_BANNER_TYPE = "main_promo_banner";
export const AD_SUB_PROMOTION_BANNER_TYPE = "sub_promo_banner";
export const AD_INBOX_THREADS_TYPE = "inbox_list";
export const AD_CATEGORY_BANNER_TOP_TYPE = "category_banner_top";
export const AD_HOME_BANNER_TOP_TYPE = "home_banner_top";
export const AD_MEDIA_IMAGE_TYPE = "image";
export const AD_MEDIA_VIDEO_TYPE = "video";
